<template>
  <section class="tables">
   
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Liste des articles
            </div>
            <nav aria-label="breadcrumb" class=" float-right">
              <ol class="breadcrumb">
                <router-link to="/ajouterproduit">
                  <li class="breadcrumb-item btn btn-primary">
                    Ajouter un article
                  </li>
                </router-link>
              </ol>
            </nav>
            <div class="table-responsive">
              <input
                value=""
                class="form-control search-input"
                placeholder="Recherche par réference"
                type="text"
                v-model="search"
              />
              <table class="table">
                <thead>
                  <tr>
                  <th>image</th> 
                    <th>#Refrence</th>
                    <th>Catégorie</th>
                    <th>Désignation</th>
                    <th>Prix</th>
                    <th>Quantités minimal</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(produit, index) in searchFunction" :key="index">
                 <td>
            <img
            width="90"

            variant="light-primary"
            v-if="produit.photo!=null"
            :src="require('@/images/'+produit.photo)"
            class="badge-minimal"
         
          />
          <img
            width="60"
            variant="light-primary"
            v-if="produit.photo==null"
                  
           
            :src="require('@/images/product.png')"
            class="badge-minimal"
         
          />
                
        
        
        </td>  
                    <td>{{ produit.reference }}</td>
                    <th>{{ produit.categorie }}</th>
                  
                    <td>{{ produit.designation }}</td>
                    <td >
                      
                      <span v-if="produit.categorie=='produit'">
                        {{ produit.prix }}
                      </span>

                      <span v-if="produit.categorie=='echantillon'||produit.categorie=='marketing'">
                        --
                      </span>
                    
                    
                    
                    </td>
                    <td>{{ produit.quantites_minimal }}</td>
                    <td>
                    <!-- <a @click="sendproduit(produit)" v-b-modal.modal-1>
                        <i
                        class="mdi mdi-eye icone "
                         
                        ></i
                      ></a>  -->
                      <router-link
                        :to="{ name: 'modifier', params: { id: produit._id } }"
                        href="#"
                      >
                        <i
                          class="mdi mdi-pen icone text-success"
                          title="Modifier le produit"
                        >
                        </i>
                      </router-link>
                      <i
                        class="mdi mdi-delete icone text-danger"
                        title="Supprimer le produit"
                        @click="deleteproduit(produit._id)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" title="information de produit">
      <br />
        <div class="wrapper">
    <div class="product-img">
      <img id="imageid" :src="require('@/images/Descriptif Format Sage - #CIVA.png')" height="420" width="327"/> 
    </div>
    <div class="product-info">
      <div class="product-text">
        <h1>{{ selectedProduit.reference }}</h1>
        <h2>{{  selectedProduit.categorie }}</h2>
        <p>Harvest Vases are a reinterpretation<br> of peeled fruits and vegetables as<br> functional objects. The surfaces<br> appear to be sliced and pulled aside,<br> allowing room for growth. </p>
      </div>
      <div class="product-price-btn">
        <p><span>78</span>$</p>
        <button type="button">buy now</button>
      </div>
    </div>
  </div>
    </b-modal>
  </section>

  <!-- <section>
    <div class="page-header">
      <h3>
        liste des Produits
      </h3>
      <nav aria-label="breadcrumb">
        <router-link class="nav-link" to="/ajouterproduit">
          <b-button class="btn btn-block btn-lg btn-gradient-primary mt-4">
            + Ajouter Un Produit
          </b-button>
        </router-link>
      </nav>
    </div>

    <div class="container">
      <div class="row py-5">
        <div class="col-12">
          <table
            id="example"
            class="table table-hover responsive nowrap"
            style="width:100%"
          >
            <thead>
              <tr>
                <th>#Refrence</th>
                <th>Prix</th>
                <th>Access</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="produit in produits"
                :key="produit.id"
                style="border-bottom: 0.5px rgb(183, 163, 183) solid;"
              >
                <td>{{ produit.reference }}</td>
                <td>{{ produit.prix }}</td>
                <td>
                  <div class="badge badge-success badge-success-alt">
                    Enabled
                  </div>
                </td>
                <td>
                  <a @click="sendproduit(produit)" v-b-modal.modal-1>
                    <i
                      class="fa fa-eye"
                      style="font-size:20px;color:#f0abe9;margin-right:20px;"
                    ></i
                  ></a>

                  <a @click="deleteproduit(produit._id)" href="#">
                    <i
                      class="fa fa-trash"
                      style="font-size:20px;color:#F96161"
                    ></i
                  ></a>
                  <router-link
                    style="font-size:20px;margin-left:20px;color:#94A9E2"
                    :to="{ name: 'modifier', params: { id: produit._id } }"
                    href="#"
                  >
                    <i class="fa fa-folder-open"></i
                  ></router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <show-produit></show-produit>

    <b-modal id="modal-1" title="information de produit">
      <br />
      <div class="container">
        <div class="row">
          <div class="block span3">
            <div class="info">
              <h4>{{ selectedProduit.reference }}</h4>
              <span class="description">
                {{ selectedProduit.description }}
              </span>
              <span class="price"> {{ selectedProduit.prix }} dt</span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </section> -->
</template>
<script>
import {
  BAvatar,
} from 'bootstrap-vue'
import { HTTP } from '@/axios';
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
   components: {  BAvatar },

  data() {
    return {
      produits: [],
      error: "",
      selectedProduit: "",
      search: "",
    };
  },

  created() {
    this.getproduits();
  },

    computed: {
    searchFunction() {
      return this.produits.filter((item) => {
        return (
          item.reference
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },


  methods: {
    sendproduit(item) {
      this.selectedProduit = item;
    },
    getproduits() {
      HTTP.get("produits/getproduits").then(
        (res) => {
          this.produits = res.data;
        },
        (err) => {
          this.error = err.response.data.error;
        }
      );
    },

    deleteproduit(produitid) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez supprimé le produit",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP
            .delete("produits/deleteproduit/" + produitid)
            .then((response) => {
              //this.$htmlToPaper("printNow");

              Toast.fire({
                position: "top-center",
                icon: "success",
                title: "Produit supprimé",
              });

              this.getproduits();
            });
        }
      });
    },
  
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
body {
  background: #f7f7f7;
}

.table {
  border-spacing: 0 0.85rem !important;
}

.table .dropdown {
  display: inline-block;
}

.table td,
.table th {
  vertical-align: middle;
  margin-bottom: 10px;
  border: none;
}

.table thead tr,
.table thead th {
  border: none;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: transparent;
}

.table td {
  background: #fff;
}

.table td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before {
  top: 28px;
  left: 14px;
  border: none;
  box-shadow: none;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child {
  padding-left: 48px;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  width: 100%;
}

table.dataTable > tbody > tr.child span.dtr-title {
  min-width: 50%;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  padding: 0.75rem 1rem 0.125rem;
}

div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_filter label {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    -ms-flex-pack: center !important;
    justify-content: center !important;
    margin-top: 1rem;
  }
}

.btn-icon {
  background: #fff;
}
.btn-icon .bx {
  font-size: 20px;
}

.btn .bx {
  vertical-align: middle;
  font-size: 20px;
}

.dropdown-menu {
  padding: 0.25rem 0;
}

.dropdown-item {
  padding: 0.5rem 1rem;
}

.badge {
  padding: 0.5em 0.75em;
}

.badge-success-alt {
  background-color: #d7f2c2;
  color: #7bd235;
}

.table a {
  color: #212529;
}

.table a:hover,
.table a:focus {
  text-decoration: none;
}

table.dataTable {
  margin-top: 12px !important;
}

.icon > .bx {
  display: block;
  min-width: 1.5em;
  min-height: 1.5em;
  text-align: center;
  font-size: 1.0625rem;
}

.btn {
  font-size: 0.9375rem;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
}

.avatar-blue {
  background-color: #c8d9f1;
  color: #467fcf;
}

.avatar-pink {
  background-color: #fcd3e1;
  color: #f66d9b;
}



.product-img {
  float: left;
  height: 420px;
  width: 327px;
}

.product-img img {
  border-radius: 7px 0 0 7px;
}

.product-info {
  float: left;
  height: 420px;
  width: 327px;
  border-radius: 0 7px 10px 7px;
  background-color: #ffffff;
}

.product-text {
  height: 300px;
  width: 327px;
}

.product-text h1 {
  margin: 0 0 0 38px;
  padding-top: 52px;
  font-size: 34px;
  color: #474747;
}

.product-text h1,
.product-price-btn p {
  font-family: 'Bentham', serif;
}

.product-text h2 {
  margin: 0 0 47px 38px;
  font-size: 13px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #d2d2d2;
  letter-spacing: 0.2em;
}

.product-text p {
  height: 125px;
  margin: 0 0 0 38px;
  font-family: 'Playfair Display', serif;
  color: #8d8d8d;
  line-height: 1.7em;
  font-size: 15px;
  font-weight: lighter;
  overflow: hidden;
}

.product-price-btn {
  height: 103px;
  width: 327px;
  margin-top: 17px;
  position: relative;
}

.product-price-btn p {
  display: inline-block;
  position: absolute;
  top: -13px;
  height: 50px;
  font-family: 'Trocchi', serif;
  margin: 0 0 0 38px;
  font-size: 28px;
  font-weight: lighter;
  color: #474747;
}



.product-price-btn button {
  float: right;
  display: inline-block;
  height: 50px;
  width: 176px;
  margin: 0 40px 0 16px;
  box-sizing: border-box;
  border: transparent;
  border-radius: 60px;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: #ffffff;
  background-color: #9cebd5;
  cursor: pointer;
  outline: none;
}

.product-price-btn button:hover {
  background-color: #79b0a1;
}


</style>
